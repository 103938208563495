import { Section } from '../components'
import { TextBlock } from '../components'

const styles = {
  text: {
    fontFamily: '"M+"',
    opacity: .9,
    fontSize: '20px',
  }
}
export const About = () => {

  return <Section style={{
    color: 'rgb(225, 225, 225)',
    flexWrap: 'wrap',
    position: 'relative',
  }}>
    <h1 className='display' style={{
      fontFamily: 'Nunki Display',
    }}>About</h1>

    <div style={{
      display: 'flex',
      flexFlow: 'column',
    }}>
      <TextBlock>
        <p style={styles.text}>
          Matt Gordeuk works professionally as a band-leader, composer, guitarist, performar and educator.
        </p>

        <p style={styles.text}>
          Fueled by a life-long dedication to fluency and expression in genres across the board - backed by an education from Vic Juris, Ralph Bowen, Gary Smulyan and Victor Lewis and a Bachelor of Music from the Mason Gross School of the Arts.
        </p>

        <p style={styles.text}>
          Notable performances at:
          <ul>
            <li>
              Chris' Jazz Cafe
            </li>
            <li>
              Tomi Jazz
            </li>
            <li>
              Somethin Jazz Club
            </li>
            <li>
              DROM
            </li>
            <li>
              Lafayette Bar
            </li>
            <li>
              JETLAG Festival
            </li>
          </ul>
        </p>
      </TextBlock>

      <hr style={{width: '100%', margin: '60px 0'}} />

      <TextBlock>
        <h2>MG3</h2>
        <p style={styles.text}>Founder and band leader of this genre bending instrumental trio.</p>

      </TextBlock>

      <TextBlock>
        <h2>Solo Guitar</h2>
        <p style={styles.text}>
          Residencies in Lambertville, NJ and New Hope, PA.
        </p>
      </TextBlock>

      <hr style={{width: '100%', margin: '60px 0'}} />

      <TextBlock>
        <h2>Far Hills Country Day School</h2>
        <p style={styles.text}>
          Jazz Band Director
        </p>
      </TextBlock>

      <TextBlock>
        <h2>Acorn Montessori School</h2>
        <p style={styles.text}>
          Established the music program, and coaches ensembles at the summer music camp.
        </p>
      </TextBlock>

      <TextBlock>
        <h2>Canal Music Studios</h2>
        <p style={styles.text}>
          Faculty Member
        </p>
      </TextBlock>

      {/*
      <div style={{
        flexFlow: 'column',
        display: 'flex',
        flexBasis: '0',
        flexGrow: '1',
      }}>
        <h2>Sign me up!</h2>
        <form style={{
          display: 'flex',
          flexFlow: 'column'
        }}>
          <label htmlFor='name'>Name</label>
          <input id='name' type="text" placeholder='Name' />

          <label htmlFor='lessonsemail'>Contact Info</label>
          <input id='email' style={{}}
            type="text" placeholder='Email' />

        </form>
      </div>
      */}

    </div>
  </Section>
}
