import {
  TextBlock,
  Section
} from '../components'

const LivePerformance = ({
  artist,
  date,
  venue,
  time,
  location,
}) => {
  return (<TextBlock style={{paddingTop: '40px'}}>
    <h2 style={{ margin: '20px 0 0px'}}>{artist} </h2>
    <p style={{ marginTop: '0px', marginBottom: 0 }}>{venue}</p>
    <p style={{ opacity: '0.7', margin: '20px 0 0' }}><code style={{ margin: '0px'}}>{location}</code></p>
    <p style={{  opacity: '0.7', margin: '0 0 0 '}}><code style={{ margin: '0' }}>{date}</code></p>
    <p style={{  opacity: '0.7', margin: '0 0 0 ', textTransform: 'uppercase'}}><code style={{ margin: '0' }}>{time}</code></p>
  </TextBlock>)
}

export const LivePerformances = () => {
  const events = [
    {
      artist: "Matt Gordeuk",
      venue: "Martine's Riverhouse Restaurant",
      date: "Every Friday",
      time: "6 - 9pm",
      location: "New Hope, PA"
    },
    {
      artist: 'Matt Gordeuk',
      venue: 'Under the Moon',
      date: 'Every Saturday',
      time: '12 - 3pm',
      location: 'Lambertville, NJ'
    }, {
      artist: 'MG3',
      venue: 'Logan Inn',
      date: 'Friday, August 13th',
      time: '8 - 11 pm',
      location: 'New Hope, PA'
    }, {
      artist: "MG3",
      date: "Saturday August 7",
      time: "12 - 3pm",
      venue: "Under The Moon",
      location: "Lambertville, NJ"
    }, {
      artist: "Matt Gordeuk",
      venue: "Crossing Vineyards",
      location: "Newtown, PA",
      date: "Sunday August 8",
      time: "2 - 6pm",
    }, {
      artist: "MG3",
      date: "Saturday August 14",
      time: "7 - 11pm",
      venue: "Bowman’s Tavern",
      location: "New Hope, PA"
    }, {
      artist: "Matt Gordeuk",
      venue: "Crossing Vineyards",
      location: "Newtown, PA",
      date: "Sunday, August 15",
      time: "2 - 6pm"
    }, {
      artist: "Matt Gordeuk",
      venue: "More Than Q",
      location: "Lambertville, NJ",
      date: "Sunday, August 28",
      time: "1 - 3pm"
    }, {
      artist: "MG3",
      date: "Saturday September 4",
      time: "12 - 3pm",
      venue: "Under The Moon",
      location: "Lambertville, NJ"
    }, {
      artist: "Matt Gordeuk",
      venue: "Hailey's Hangout and Parlor",
      location: "Metuchen, NJ",
      date: "Saturday, September 11",
      time: "7:30-10:30"
    }, {
      artist: 'MG3',
      venue: 'Invertase Brewing Company',
      date: 'Saturday, September 25th',
      time: '5 pm',
      location: 'Phillipsburg, NJ'
    }, {
      artist: "MG3",
      date: "Saturday October 2",
      time: "11 - 2pm",
      venue: "Under The Moon",
      location: "Lambertville, NJ"
    }, {
      artist: "MG3",
      date: "Saturday October 23",
      time: "5:30 - 8:30pm",
      venue: "Under The Moon",
      location: "Lambertville, NJ"
    }
  ]

  return <Section style={{
    color: 'black',
    backgroundColor: '#ffc961',
  }}>
    <h1 className='display' style={{ color: '#rgb(49, 15, 42)', fontFamily: 'Nunki Display' }}>Live Performances</h1>

    {events.map( (data, i) => <LivePerformance key={i} {...data} />)}
  </Section>
}
